import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { SnackbarKey, useSnackbar } from 'notistack'

interface Props {
  snackKey: SnackbarKey;
}

export default function CloseSnackbarButton ({ snackKey }: Props) {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(snackKey)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )
}
