import CloseSnackbarButton from '@components/shared/CloseSnackbarButton'
import '@styles/globals.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { SnackbarProvider } from 'notistack'
import React, { ReactElement, ReactNode } from 'react'

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App ({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
  if (typeof window === 'undefined') React.useLayoutEffect = React.useEffect

  const getLayout = Component.getLayout ?? ((page) => page)

  const [queryClient] = React.useState(() => new QueryClient())

  return (
    <SnackbarProvider
      hideIconVariant
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      style={{
        fontSize: 16,
        fontWeight: '400',
        borderRadius: '12px'
      }}
      action={(key) => <CloseSnackbarButton snackKey={key} />}
    >
      <QueryClientProvider client={queryClient}>
        {getLayout(<Component {...pageProps} />)}
      </QueryClientProvider>
    </SnackbarProvider>
  )
}

export default App
